<style scoped>
span {
  font-size: 15px;
}

@media print {
    .contentisi { page-break-before: always; } /* page-break-after works, as well */
}

</style>

<style type="text/css" media="print">
  @page { size: landscape; margin: 2mm 2mm 2mm 2mm;}
    table tr td {
        font-size: 9pt;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
</style>
<template>
   <title>Print Reporting Accounting Trial Balance</title>
   <body>
       <br>
       <button style="margin-left:15px;width: 60%;" size @click="printsurat()" type="button" class="btn btn-info btn-block"><i class="fa fa-print"></i> Cetak</button>
       <div class="print-data">
           <div class="contentisi">
               <section class="content">
                   <div class="row">
                        <div class="col-md-8" style="margin-top:15px">
                            <table width="100%" class="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <td width="27%">Name</td>
                                        <td width="11%">Saldo Awal Debit</td>
                                        <td width="11%">Saldo Awal Kredit</td>
                                        <td width="11%">Saldo Bergerak Debit</td>
                                        <td width="11%">Saldo Bergerak Kredit</td>
                                        <td width="14%">Saldo Akhir Debit</td>
                                        <td width="14%">Saldo Akhir Kredit</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1-0000 Asset</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 10px;">1-1100  CASH</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1101  Cash On Hand - IDR</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1102  Cash on Hand - USD</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <!-- ============= -->
                                    <tr>
                                        <td style="text-indent: 10px;">1-1200  BANK</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1201  Bank Mandiri - IDR</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1202  Bank Mandiri USD</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1203  Bank HSBC IDR</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1204  Bank HSBC USD</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <!-- ============= -->
                                    <tr>
                                        <td style="text-indent: 10px;">1-1300  DEPOSITS</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1301  Deposit on Call IDR</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1302  Deposit on Call USD</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <!-- ============= -->
                                    <tr>
                                        <td style="text-indent: 10px;">1-1400  RECEIVABLES</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1401  AR Director - Andy Chan</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1402  Danny Wong</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1403  Hayes</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1404  Master Kidz Asia - Reimburstment</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1405  Master Kids Asia - A/R</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1406  Agus Dedi Deviadi - A/R</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1407  Erna Yulianti - A/R</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1408  PT. Kawasan Industri Kendal - A/R</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1409  Surya Fuadi - A/R</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style="text-indent: 20px;">1-1410  Adi Saputro</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                   </div>
               </section>
           </div>
       </div>
   </body>
</template>

<script>
export default {
    name:"cetakTrialBalance",
    methods: {
        printsurat(){
            var newstr = document.getElementsByClassName('print-data')[0].innerHTML
            // 2. Copy to body and execute window. print printing
            document.body.innerHTML = newstr
            // 3. Restore: Store the old page and return it to the page after printing.
            var oldstr = document.body.innerHTML
            window.print()
            document.body.innerHTML = oldstr
            return false
        },
    }
}
</script>